<template>
  <div class="content-layout-fixer" style="padding: 20px 0 0 20px;" v-if="results">
    <div class="agreement">
      <div class="static-desc" v-html="contractContent"></div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4"
          >Bayi Ünvanı</VueText
        >
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerName
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4"
          >Bayi Kodu</VueText
        >
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerCode
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4"
          >Tutar - TL</VueText
        >
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerAgreementAmountValue
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4"
          >Tutar - Puan</VueText
        >
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerAgreementPointValue
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4"
          >Başlangıç Tarihi</VueText
        >
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerAgreementStartDate
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4"
          >Bitiş Tarihi</VueText
        >
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
        results.customerAgreementEndDate.slice(0, 10),
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4">Süre(ay)</VueText>
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.month
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4">VKN</VueText>
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerTaxNumber
        }}</VueText>
      </div>
      <div class="flex">
        <VueText class="flex-item" color="gray-800" sizeLevel="6" weightLevel="4">Adres</VueText>
        <VueText class="flex-item" color="gray-800" sizeLevel="5" weightLevel="2">{{
          results.customerAddress
        }}</VueText>
      </div>
      <div v-if="!!results.hasFlexiblePayment" class="flex">
        <VueText class="flex-item " color="gray-800" sizeLevel="6" weightLevel="4"
          >Aylık Ödeme(Puan)</VueText
        >

        <div class="table-wrapper">
          <table class="fl-table">
            <thead>
              <tr>
                <th v-for="(m, i) in months" :key="i">{{ m.month }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(payment, index) in flexiblePayments" :key="index">
                <td v-for="(p, i) in payment" :key="i">{{ p }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <VueText style="margin: 16px 0" color="gray-800" sizeLevel="7" weightLevel="4"
        >Kriterler (Kriter Ağırlığı)</VueText
      >

      <div v-for="(item, index) in List" :key="index">
        <div class="flex " v-if="item.productCode">
          <VueText class="flex-item-left" color="gray-800" sizeLevel="6" weightLevel="4"
            >{{ item.questionDescription }} - {{ item.productCode }}</VueText
          >
          <VueText class="flex-item-right" color="gray-800" sizeLevel="5" weightLevel="2">{{
            item.customerAgreementCriteriaPercentage
          }}</VueText>
        </div>
        <div class="flex" v-else>
          <VueText class="flex-item-left" color="gray-800" sizeLevel="6" weightLevel="4">{{
            item.questionDescription
          }}</VueText>
          <VueText class="flex-item-right" color="gray-800" sizeLevel="5" weightLevel="2">{{
            item.customerAgreementCriteriaPercentage
          }}</VueText>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Agreement } from '@/services/Api/index';
import StaticContent from '@/services/Api/staticContents';
import VueText from '@/components/shared/VueText/VueText.vue';
export default {
  name: 'AgreementsDetail',
  components: {
    VueText,
  },
  data() {
    return {
      contractContent: '',
      results: null,
      List: [],
      months: [
        { month: '' },
        { month: 'Ocak' },
        { month: 'Şubat' },
        { month: 'Mart' },
        { month: 'Nisan' },
        { month: 'Mayıs' },
        { month: 'Haziran' },
        { month: 'Temmuz' },
        { month: 'Ağustos' },
        { month: 'Eylül' },
        { month: 'Ekim' },
        { month: 'Kasım' },
        { month: 'Aralık' },
        { month: 'Toplam' },
        { month: ' Güncel Toplam ' },
      ],
      flexiblePayments: [],
    };
  },
  beforeMount() {
    let agreementId = this.$route.params.id;
    Agreement.getAgreementDetail(agreementId).then(res => {
      this.results = res.data.Data;
      this.List = this.results.details;

      res.data.Data.flexiblePayments.forEach(f => {
        this.flexiblePayments.push(f);
      });

      this.flexiblePayments.forEach(f => {
        const initialValue = 0;
        let sumWithInitial = f.reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, initialValue);
        f.push(sumWithInitial - f[0], sumWithInitial - f[0]);
      });
    });

    StaticContent.getStaticContent('TPSozlesmesi').then(res => {
      let {
        Data: { content },
      } = res.data;

      this.contractContent = content;
    });
  },
};
</script>
<style lang="scss" scoped>
.agreement {
  padding: 32px;
  border: 4px solid black;
  .static-desc {
    max-height: palette-space-level(400);
    overflow: auto;
    padding: palette-space-level(20);
    margin-bottom: 16px;
  }
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  align-items: center;
  border-bottom: 2px solid #cdc8c89e;
  .flex-item {
    align-content: stretch;
    width: 50%;
    margin-bottom: 8px;
  }
  .flex-item-left {
    margin-right: 8px;
    width: 80%;
    margin-bottom: 8px;
  }
}

.table-wrapper {
  padding: 20px 0 30px 0;
}

.fl-table {
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  max-width: 100%;
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 10px;
  border: 1px solid rgb(231, 228, 228);
  padding: 0.5rem;
}

.fl-table tr:nth-child(even) {
  background: #f8f8f8;
}

.fl-table td {
  font-size: 14px;
}

.fl-table thead th {
  color: #000000;
  background: #f8f8f8;
}
</style>
